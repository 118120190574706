import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  ActorType,
  AppEventModel,
  AppEventType,
  ClientShortModel,
  ComponentType, CountryModel,
  DictionaryEntryModel,
  DictionaryKey,
  DiscountResultModel,
  DiscountsResponse,
  FileModel,
  Folder,
  HX_COMPONENT_NAME,
  HxAuthService, HxCountryService,
  HxDictionaryService,
  HxFileService,
  HxOrderService,
  HxStoreService,
  isProductDiscounted,
  NotifierEventBusAddress,
  NotifierService,
  OrderAction,
  OrderResponse,
  OrderStatus,
  PaymentModel,
  PermissionKey,
  ProductStatus,
  StoreFullModel,
  TerminalActionStatus
} from 'hx-services';
import { format } from 'date-fns';
import { HxErrorHandlerService, HxModalActorComponent, HxOrderReceiptComponent, HxToastrService } from 'hx-component';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import Timeout = NodeJS.Timeout;

/**
 * Component view order info
 */
@Component({
  selector: 'hx-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.css']
})
export class HxOrderInfoComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('templatePdf') templatePdf!: TemplateRef<any>;
  @ViewChild('templateImage') templateImage!: TemplateRef<any>;

  @Input() order!: OrderResponse;
  @Output() orderChange = new EventEmitter<OrderResponse>();

  fileDownloadUrl = HxFileService.fileDownloadUrl;
  isArchive = false;
  statusMap = new Map<string, string>([
    ['CREATED', 'info'],
    ['PACKED', 'warning'],
    ['DECORED', 'focus'],
    ['SHIPPED', 'warning'],
    ['RECEIVED', 'success'],
    ['CANCELLED', 'danger'],
    ['NEW', 'info'],
    ['REJECTED', 'danger'],
    ['SHIPMENT', 'warning'],
    ['DELIVERED', 'focus'],
    ['PAID', 'success'],
    ['TAKEN', 'warning'],
    ['RETURNED', 'metal'],
    ['REFUNDED', 'danger'],
  ]);
  showTime = 'dd.MM HH:mm';
  image?: { name: string, path: string | SafeResourceUrl, mimeType: string };
  isLoading = {
    order: true,
    printing: false,
  };
  showAllProducts = false;
  discounts: DiscountResultModel[] = [];
  store?: StoreFullModel;
  pdfUrl?: SafeResourceUrl;
  decorFileCount = 0;
  paymentOrderFileCount = 0;
  countDown = 0;
  status = 'NONE';
  totalSAmount = 0;
  totalGram = 0;
  hasFeedbackPermission = false;
  headerClass = '';
  letterOfAuthorityUrl?: string;
  referrerEntry?: DictionaryEntryModel;
  isCart = false;
  hasAudioPermission = false;
  isCallcenter = false;
  isManager = false;
  isCashbox = false;
  country?: CountryModel;
  private interval?: Timeout;
  private $destroyed = new Subject<void>();

  constructor(
    private modal: NgbModal,
    private aRoute: ActivatedRoute,
    private router: Router,
    private toastr: HxToastrService,
    private storeService: HxStoreService,
    private domSanitizer: DomSanitizer,
    private errorHandlerService: HxErrorHandlerService,
    private orderService: HxOrderService,
    private auth: HxAuthService,
    private fileService: HxFileService,
    private notifierService: NotifierService,
    private dictionary: HxDictionaryService,
    private tr: TranslocoService,
    private countryService: HxCountryService,
    @Optional() @Inject(HX_COMPONENT_NAME) private componentName: string,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['order'].isFirstChange()) {
      console.log('ng on changes for order-info.component.ts called');
      this.initOrder(false);
    }
  }

  private orderEventHandler: (err: any, message: { body: any, headers: any }) => void = (err, message) => {
    if (err) {
      console.error(err);
    } else {
      if (message.headers.action === 'orderPaid') {
        if (!this.modal.hasOpenModals()) {
          this.toastr.info(this.tr.translate('ord.order-info.ts.orderPaid'));
        }
        this.loadOrder();
      }
    }
  };

  async ngOnInit() {
    this.isCallcenter = this.componentName === ComponentType.cc;
    this.isManager = this.componentName === ComponentType.manager;
    this.isCashbox = this.componentName === ComponentType.cb;

    this.auth.hasPermission(PermissionKey.feedback_support).then(result => this.hasFeedbackPermission = result.hasPermission);
    if (this.isCallcenter) {
      this.hasAudioPermission = true;
    } else {
      this.auth.hasPermission(PermissionKey.call_record_download).then(result => this.hasAudioPermission = result.hasPermission);
    }
    this.initOrder(false);
    this.notifierService.eventBusOpenObs.pipe(takeUntil(this.$destroyed)).subscribe(eb => {
      if (eb) {
        this.notifierService.registerHandler(NotifierEventBusAddress.orderEvent(this.order.id), this.orderEventHandler);
      }
    });
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.notifierService.unregisterHandler(NotifierEventBusAddress.orderEvent(this.order.id), this.orderEventHandler);
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  showClientModal(client: ClientShortModel) {
    const modalRef = this.modal.open(HxModalActorComponent, {size: 'lg'});
    modalRef.componentInstance.actorId = client.id;
    modalRef.componentInstance.actorType = ActorType.CLIENT;
  }

  goBack(): void {
    if (window.history.length > 0) {
      window.history.back();
    } else {
      this.router.navigateByUrl('/orders/list?activeCart=false');
    }
  }

  showImage(file: FileModel): void {
    this.image = {
      name: file.fileName,
      path: this.fileService.getDownloadUrl(file.guid),
      mimeType: file.mimeType,
    };
    if (file.mimeType === 'application/pdf') {
      this.image.path = this.domSanitizer.bypassSecurityTrustResourceUrl(this.fileService.getDownloadUrl(file.guid));
    }
    this.modal.open(this.templateImage, {size: 'lg'}).result.finally(() => this.resetImage());
  }

  showPdf(guid: string) {
    this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.fileService.getDownloadUrl(guid));
    this.modal.open(this.templatePdf, {size: 'lg'}).result.finally(() => this.resetImage());
  }

  closeModal(dialog: NgbModalRef) {
    this.resetImage();
    dialog.dismiss();
  }

  isTotalShow(): boolean {
    return this.order.total < this.order.subTotal || !!this.discounts.length;
  }

  isAvailableProduct(productStatus: string): boolean {
    return (this.showAllProducts && productStatus === ProductStatus.CANCELLED) || productStatus !== ProductStatus.CANCELLED;
  }

  isCancelledProduct(productStatus: ProductStatus, payment?: PaymentModel): boolean {
    return productStatus === ProductStatus.CANCELLED && !payment;
  }

  isDiscounted(productId: number): boolean {
    return isProductDiscounted(productId, this.discounts);
  }

  isPaymentOrderFolder(folder: string): boolean {
    return folder === Folder.PAYMENT_ORDER;
  }

  toggleImportant() {
    this.orderService.setImportant(this.order.id, !this.order.important).subscribe(() => {
      const str = !this.order.important ? this.tr.translate('ord.order-info.ts.addFavorites') : this.tr.translate('ord.order-info.ts.deleteFavorites');
      this.toastr.success(this.tr.translate('ord.order-info.ts.orderPaid', {uniqueNumber: this.order.uniqueNumber, str: str}));
      this.loadOrder();
    });
  }

  unarchive(orderId: number) {
    this.orderService.unarchive(orderId).subscribe(() => {
      this.toastr.success(this.tr.translate('ord.order-info.ts.unarchiveSuccess'));
      this.loadOrder();
    }, err => this.errorHandlerService.check(err.error));
  }

  onEventClickedFn: (appEvent: AppEventModel) => Promise<any> = appEvent => {
    if (appEvent.type === AppEventType.PAYMENT_STARTED) {
      // recheck if payment finished
      return firstValueFrom(this.orderService.checkPaymentStatusByEventId({
        orderId: appEvent.containerId,
        eventId: appEvent.id
      })
        .pipe(tap(result => {
          if (result.paid) {
            this.toastr.info(this.tr.translate('ord.order-info.ts.paidSuccess'));
            if (!this.order.paid) {
              this.orderService.orderUpdated();
            }
          } else {
            this.toastr.info(this.tr.translate('ord.order-info.ts.paidFatal'));
          }
        })));
    }
    return Promise.resolve();
  };

  async showCheck() {
    if (this.country?.domain === 'us') {
      const response = await this.orderService.getSquareupReceiptUrl(this.order.id);
      console.log('url=', response.receiptUrl);
      window.open(response.receiptUrl, '_blank');

      // const modalInstance = this.modal.open(HxOrderReceiptUsComponent, {windowClass: 'modal--check'});
      // modalInstance.componentInstance.orderId = this.order.id;
    } else {
      const modalInstance = this.modal.open(HxOrderReceiptComponent, {windowClass: 'modal--check'});
      modalInstance.componentInstance.checks = [{orderId: this.order.id}];
      modalInstance.componentInstance.isView = true;
    }
  }

  async printCheck() {
    this.isLoading.printing = true;
    const response = await this.orderService.printSquareupReceipt(this.order.id);
    if (response) {
      this.isLoading.printing = false;
    }
  }

  private startTimeout(seconds: number) {
    this.countDown = seconds;

    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      if (this.countDown > 0) {
        this.countDown -= 1;
      } else {
        this.goBack();
      }
    }, 1000);
  }

  private checkCartExpiration() {
    this.orderService.getCartExpiration(this.order.id).subscribe(expiration => {
      this.status = expiration.status;
      if (expiration.status === 'ACTIVE') {
        this.startTimeout(expiration.seconds);
      } else {
        if (this.interval) {
          clearInterval(this.interval);
        }
      }
    });
  }

  private initOrder(emit = true): void {
    this.orderService.getDiscountList(this.order.id).then((result: DiscountsResponse) => {
      this.discounts = result.results || [];
    });
    this.orderService.getTracking(this.order.id).then(tracking => {
      if (tracking?.referrer) {
        this.dictionary.getEntries({key: DictionaryKey.utmReferrer, limit: 1000}).then(({list, count}) => {
          this.referrerEntry = list.find(en => en.code === tracking.referrer);
        });
      }
    });

    this.decorFileCount = this.order.files.filter((r) => r.folder === Folder.DECOR).length;
    const letterOfAuthority = this.order.files.find(r => r.folder === Folder.LETTER_OF_AUTHORITY);
    if (letterOfAuthority) {
      this.letterOfAuthorityUrl = this.fileService.getDownloadUrl(letterOfAuthority.guid);
    }
    this.paymentOrderFileCount = this.order.files.filter((r) => r.folder === Folder.PAYMENT_ORDER).length;
    this.showAllProducts = [OrderAction.CANCELLED, OrderAction.REFUNDED].includes(this.order.action);
    const time = format(new Date(this.order.date), 'HH:ss');
    this.showTime = (time && +time.split(':')[0] > 0) ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';
    this.totalCounter();
    this.storeService.getFullStore(this.order.storeId).then(async store => {
      this.store = store;
      this.country = await this.countryService.getCountryById(this.store.countryId);
    });
    this.checkCartExpiration();
    this.isArchive = this.order.archived ?? false;
    this.isCart = this.order.status === OrderStatus.CART;
    if (this.isCart) {
      this.headerClass = 'm-portlet--warning';
    } else {
      const status = this.statusMap.get(this.order.action);
      if (status) {
        this.headerClass = 'm-portlet--' + status;
      }
    }
    this.isLoading.order = false;
    if (emit) {
      this.orderChange.emit(this.order);
    }
  }

  private async loadOrder() {
    this.isLoading.order = true;
    try {
      this.order = await this.orderService.getFullOrderById(this.order.id);
      this.initOrder();
    } finally {
      this.isLoading.order = false;
    }
  }

  private resetImage() {
    this.image = undefined;
  }

  private totalCounter() {
    this.totalSAmount = 0;
    this.totalGram = 0;

    if (this.order.products && this.order.products.length) {
      this.order.products.forEach(element => {
        if (element.status !== 'CANCELLED' || this.order.action === OrderAction.CANCELLED) {
          this.totalSAmount += element.amount;
          this.totalGram += element.weight * element.amount || 0;
        }
      });
    }
  }
}
